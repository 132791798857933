import React, { useState, useEffect } from "react";
import AddressModal from "./AddressModal";
import getStripe from "./getStripe";
import useResize from "./Resize";
import { useNavigate } from "react-router-dom";

import StripeContainer from "./Stripe/StripeContainer";

import Cart from "./Cart";

import styles from "./Main.module.css";
import axios from "axios";

const Main = () => {
  const navigate = useNavigate();

  let size = useResize();
  const [address, setAddress] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [cartOpen, setCartOpen] = useState();
  const [paymentSuccess, setPaymentSuccess] = useState(false)

useEffect(() => {
  if (paymentSuccess) {
    setInterval(() => {
      console.log("PAYMENT SUCCESSFUL")
    }, 1000)
  }
}, [paymentSuccess])

  const openModal = () => {
    setModalOpen(!modalOpen);
    setCartOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const navigateToSuccess = () => {
    navigate("/success/1");
  };

  const checkout = async () => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1NRcrmGXeMoAmTUHhcj4kSrK",
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: "https://hungrysrva.com/success/1",
      cancelUrl: "https://hungrysrva.com/success/2",
    });
    console.warn(error.message);
  };

  const toggleCart = () => {
    setCartOpen(!cartOpen);
  };

  const addItems = () => {
    let random = Math.random();
    let item = {
      priceId: random < 0.5 ? "blah blah blah" : "pork pork pork",
      price: 400,
      name: random < 0.5 ? "Mac and Cheese and cheese and cheese" : "Chili Mac",
    };
    setItems((items) => {
      let prevItems = [...items];
      prevItems.push(item);
      return prevItems;
    });
  };

  const clearItems = () => {
    setItems([]);
  };

  const incrementCartItem = (incrementDirection, item) => {
    let mutableItems = [...items];
    if (incrementDirection > 0) {
      let itemWithoutQuantity = { ...item };
      delete itemWithoutQuantity.quantity;
      mutableItems.push(itemWithoutQuantity);
    } else {
      for (let i = mutableItems.length - 1; i >= 0; i--) {
        if (mutableItems[i].priceId === item.priceId) {
          mutableItems.splice(i, 1);
          break;
        }
      }
    }
    setItems(mutableItems);
  };

  const testFunc = async () => {
    let response = await axios.get(
      "https://7c962zzt65.execute-api.us-east-1.amazonaws.com/default/"
    );
    console.log(response);
  };

  return (
    <div className={styles.main}>
      <button onClick={() => console.log(address)}>log state</button>
      <button onClick={() => checkout()}>checkout</button>
      <button onClick={() => toggleCart()}>cart</button>
      <button onClick={() => addItems()}>Add items</button>
      <button onClick={() => clearItems()}>Clear items</button>
      <button onClick={() => navigateToSuccess()}>Success</button>
      <button onClick={() => testFunc()}>test api</button>
      <button
        style={{
          backgroundColor: "green",
        }}
        onClick={() => openModal()}
      >
        openModal
      </button>

      <AddressModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        setAddress={setAddress}
      />
      <Cart
        items={items}
        cartOpen={cartOpen}
        incrementCartItem={incrementCartItem}
      />
      <StripeContainer setPaymentSuccess={setPaymentSuccess}/>
    </div>
  );
};

export default Main;
