import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

const productionKey = "pk_test_aye5ZOchwiWC8LBCNQUgL7Cy";

const stripePromise = loadStripe(productionKey);

const Stripe = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm setPaymentSuccess={props.setPaymentSuccess}/>
    </Elements>
  );
};

export default Stripe;
