import React, { useEffect } from "react";
import styles from "./Cart.module.css";

const Cart = (props) => {
  useEffect(() => {}, [props.items]);

  const mapitems = () => {
    let newItems = props.items
      .reduce((acc, curr) => {
        let mutableAcc = [...acc];

        if (mutableAcc.length > 0) {
          let hasDuplicate = false;
          for (let i = 0; i < mutableAcc.length; i++) {
            if (mutableAcc[i].priceId === curr.priceId) {
              console.log(true);
              mutableAcc[i].quantity = mutableAcc[i].quantity + 1;
              hasDuplicate = true;
              break;
            }
          }
          if (!hasDuplicate) {
            console.log("longer than one and no duplicates");
            let currWithQuantity = { ...curr };
            currWithQuantity.quantity = 1;
            console.log(currWithQuantity);
            mutableAcc.push(currWithQuantity);
            console.log(mutableAcc);
          }
        } else {
          console.log("no items present");
          let currWithQuantity = { ...curr };
          currWithQuantity.quantity = 1;
          console.log(currWithQuantity);
          mutableAcc.push(currWithQuantity);
          console.log(mutableAcc);
        }

        return mutableAcc;
      }, [])
      .map((item, index) => {
        let price = "$ " + (Number(item.price) * Number(item.quantity)) / 100;
        return (
          <div key={index} className={styles.item}>
            <p className={styles.itemName}>{item.name}</p>
            <p className={styles.itemPrice}>{price.toString()}</p>
            <p className={styles.itemQuantity}>{item.quantity}</p>
            <div className={styles.increment}>
              <div
                className={styles.minus}
                onClick={() => props.incrementCartItem(-1, item)}
              >
                -
              </div>
              <div
                className={styles.plus}
                onClick={() => props.incrementCartItem(1, item)}
              >
                +
              </div>
            </div>
          </div>
        );
      });
    if (newItems.length < 1) {
      return (
        <h1 className={styles.noItems} style={{ color: "white" }}>
          No items selected
        </h1>
      );
    } else {
      return newItems;
    }
  };

  return (
    <div
      className={styles.cartWrapper}
      id={props.cartOpen ? styles.cartUp : styles.cartDown}
    >
      <div className={styles.itemsWrapper}>{mapitems()}</div>
    </div>
  );
};

export default Cart;
