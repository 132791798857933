import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error) {
      console.log("Stripe23 | token generated!", paymentMethod);
      let response = await axios.post(
        "https://7c962zzt65.execute-api.us-east-1.amazonaws.com/default/testFunc",
        {
          amount: 100,
          id: paymentMethod.id,
        }
      );
      console.log("response", response);
      props.setPaymentSuccess(true);
    } else {
      console.log(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 400 }}>
      <CardElement />
      <button>Pay</button>
    </form>
  );
};

export default CheckoutForm;
