import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

const Success = () => {
  useEffect(() => {
    console.log(id, typeof id);
  }, []);

  const { id } = useParams();

  return (
    <div>
      <h1>{Number(id) === 1 ? "Success" : "Failure"}</h1>
    </div>
  );
};

export default Success;
