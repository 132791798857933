import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useResize from "./Resize";

import styles from "./AddressModal.module.css";

const AddressModal = (props) => {
  const [placesResponse, setPlacesResponse] = useState();
  const input = useRef();
  const modal = useRef();

  const inputField = useRef();
  const [highlighted, setHighlighted] = useState();
  let size = useResize();

  const baseURL =
    "https://7c962zzt65.execute-api.us-east-1.amazonaws.com/default/";

  useEffect(() => {
    console.log("modal open use effect");
    window.addEventListener("keyup", keyPressed);

    if (props.modalOpen) {
      modal.current.style.visibility = "visible";

      if (inputField.current) {
        inputField.current.focus();
      }
    } else {
      setTimeout(() => {
        modal.current.style.visibility = "hidden";
      }, 200);
    }
    return () => {
      window.removeEventListener("keyup", keyPressed);
    };
  }, [props.modalOpen]);

  const findAddress = async () => {
    if (!input.current) {
      return;
    }
    console.log("finding address");
    let response = await axios.post(baseURL + "findAddress", {
      input: input.current,
    });
    console.log(response);
    setPlacesResponse(response);
  };

  const keyPressed = (event) => {
    if (
      inputField.current === document.activeElement &&
      event.key === "Enter"
    ) {
      findAddress();
    }
  };

  const changeAddress = () => {
    setPlacesResponse();
    setHighlighted(null);
  };

  const highlight = (index) => {
    setHighlighted(index);
  };

  const chooseAddress = () => {
    if (highlighted === undefined) {
      return;
    }
    props.setAddress(placesResponse.data.predictions[highlighted].description);
    setTimeout(() => {
      setPlacesResponse();
    }, 300);
    props.closeModal();
    setHighlighted(undefined);
    input.current = null;
  };

  const mapResults = () => {
    return placesResponse.data.predictions.map((prediction, index) => {
      return (
        <div
          key={index}
          onClick={() => highlight(index)}
          className={styles.resultWrapper}
          style={{
            backgroundColor: highlighted === index ? "yellow" : null,
          }}
        >
          <h3 className={styles.h3}>{prediction.description}</h3>
        </div>
      );
    });
  };

  const closeModal = () => {
    setTimeout(() => {
      setPlacesResponse();
    }, 300);
    setHighlighted(null);
    input.current = null;
    props.closeModal();
  };

  return (
    <div
      ref={modal}
      className={styles.mainWrapper}
      id={styles[props.modalOpen ? "in" : "out"]}
    >
      <div className={styles.x}>
        <button className={styles.closeButton} onClick={() => closeModal()}>
          X
        </button>
      </div>

      <div className={styles.mainBody}>
        {!placesResponse && (
          <input
            ref={inputField}
            placeholder="Address"
            className={styles.input}
            onChange={(event) => (input.current = event.target.value)}
          ></input>
        )}
        {placesResponse && mapResults()}
        {placesResponse?.data.status === "ZERO_RESULTS" && (
          <>
            <h3 className={styles.h3}>No Results</h3>
          </>
        )}
      </div>
      <div className={styles.lowerBody}>
        <button
          className={styles.lowerBodyButton}
          onClick={() => findAddress()}
          style={{
            display: !placesResponse ? "block" : "none",
          }}
        >
          find address
        </button>

        <button
          className={styles.lowerBodyButton}
          onClick={() => chooseAddress()}
          style={{
            display:
              placesResponse && placesResponse.data.status !== "ZERO_RESULTS"
                ? "block"
                : "none",
          }}
        >
          Select Address
        </button>
        <button
          className={styles.lowerBodyButton}
          onClick={() => changeAddress()}
          style={{
            display:
              placesResponse && placesResponse.data.status !== "ZERO_RESULTS"
                ? "block"
                : "none",
          }}
        >
          Change Address
        </button>

        <button
          className={styles.lowerBodyButton}
          onClick={() => changeAddress()}
          style={{
            display:
              placesResponse?.data.status === "ZERO_RESULTS" ? "block" : "none",
          }}
        >
          Change Address
        </button>
      </div>
    </div>
  );
};

export default AddressModal;
