import "./App.css";
import Main from "./components/Main";
import Success from "./components/Success";
import TestPayment from "./components/TestPayment";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Main />}></Route>
        <Route exact path="/test" element={<TestPayment />}></Route>
        <Route exact path="/success/:id" element={<Success />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
